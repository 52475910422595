import React from "react"
import "./i18n"
import { useTranslation } from "react-i18next"
import Loader from "./loader"

const Footer = () => {
  const { t, ready } = useTranslation("footer")

  return !ready ? (
    <Loader />
  ) : (
    <section className="section_background--dark no_margin--top ">
      <div className="col center margin_top--big fillWidth">
        <p className=" basic_text  light ">{t("contact")}</p>
        <div className="row margin left">
          <p className=" basic_text light">{t("email")}:</p>
          <p className=" basic_text light margin_left ">contact@zakazi.app</p>
        </div>
      </div>
    </section>
  )
}

export default Footer
