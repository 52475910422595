import React from "react"
import { useTranslation } from "react-i18next"
import Contact from "../components/contact"
import Feature, { BigFeature } from "../components/feature"
import "../components/i18n"
import Image from "../components/image"
import Layout from "../components/layout"
import Loader from "../components/loader"
import SEO from "../components/seo"
import "../styles/main.scss"
import Footer from "../components/footer"

const IndexPage = () => {
  const { t, ready } = useTranslation("index")

  return !ready ? (
    <Loader />
  ) : (
    <Layout>
      <SEO title={t("title")} />

      <Image path="header.svg" alt="header" style="header_background" />
      <div className="col center">
        <div className="row">
          <div>
            <Image alt="screen" path="phone.svg" style="screen_mock--big" />
          </div>
          <div className="half_width left">
            <p className="basic_text dark">{t("longText")}</p>
          </div>
        </div>
      </div>

      <div className="main-content margin_top--large">
        <div id="about">
          <Feature
            alt="notification"
            path="notification-illustration.svg"
            title={t("notificationTitle")}
            description={t("notificationText")}
          />
          <Feature
            alt="vacation"
            path="vacation.svg"
            title={t("vacationTitle")}
            description={t("vacationText")}
            right
          />
          <Feature
            alt="alert"
            path="notify.svg"
            title={t("alertTitle")}
            description={t("alertText")}
          />
          <Feature
            alt="signins"
            path="sign-ins.svg"
            title={t("signInsTitle")}
            description={t("signInsText")}
            right
          />

          <Feature
            alt="flexibility"
            path="flexibility.svg"
            title={t("flexibilityTitle")}
            description={t("flexibilityText")}
          />
          <Feature
            alt="visibility"
            path="visibility.svg"
            title={t("visibilityTitle")}
            description={t("visibilityText")}
            right
          />
          <Feature
            alt="qr"
            path="qr-code.svg"
            title={t("qrCodeTitle")}
            description={t("qrCodeText")}
          />
        </div>
        <BigFeature
          paths={[
            { path: "barber.svg", alt: "barber" },
            { path: "car.svg", alt: "car" },
            { path: "cosmetic.svg", alt: "cosmetic" },
            { path: "dentist.svg", alt: "dentist" },
            { path: "doctor.svg", alt: "doctor" },
            { path: "nails.svg", alt: "nails" },
            { path: "solarium.svg", alt: "solarium" },
            { path: "wax.svg", alt: "wax" },
          ]}
          title={t("createdForTitle")}
          description={t("createdForText")}
        />

        <div className="bottom_content">
          <Image
            path="header-reverse.svg"
            style="header_background__rotated"
            alt="footer"
          />
          {/* <HeaderReverseSvg className="header_background__rotated" /> */}
          <div className="col center margin_top--large">
            <p className=" basic_text dark bold big" id="download">
              {t("downloadTheApplication")}
            </p>
            <div className="row wrap center margin_top">
              <a
                className="no_margin"
                href="https://apps.apple.com/app/id1465496988"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt="appstore"
                  path="appstore.png"
                  style="store_icon margin_vertical"
                />
              </a>
              <a
                className="no_margin"
                href="https://play.google.com/store/apps/details?id=com.zakazi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt="playstore"
                  path="playstore.png"
                  style="store_icon margin_vertical"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col fillWidth" id="contact">
          <Contact />
        </div>
        <div className="col fillWidth" id="footer">
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
