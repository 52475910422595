import { graphql, useStaticQuery } from "gatsby"
import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ alt, style, path }) => {
  const {
    allFile: { group },
  } = useStaticQuery(
    graphql`
      {
        allFile {
          group(field: sourceInstanceName) {
            nodes {
              extension
              publicURL
            }
          }
        }
      }
    `,
    { variables: { path } }
  )
  const data = group[0].nodes.find(
    node => node.publicURL.split("/")[3] === path
  )
  // if (!data.childImageSharp && data.extension === "svg") {
  return <img className={style} src={data.publicURL} alt={alt} />
  // }
  // return <Img fluid={data.childImageSharp.fluid} />
  // return null
}

export default Image
