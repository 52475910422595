import React, { useRef, useState, useEffect } from "react"
import "../components/i18n"
import Image from "./image"
import Fade from "react-reveal/Fade"

export const BigFeature = ({ paths, title, description }) => {
  return (
    // <Fade bottom>
    <section className="col center margin_top--big">
      <div className="col center margin_top--big">
        <p className=" basic_text  bold big ">{title}</p>
        <p className="margin_top--big basic_text ">{description}</p>
      </div>
      <div className="row wrap">
        {paths?.map((path, index) => (
          <Image
            key={index}
            alt={path.alt}
            path={path.path}
            style="small_svgs margin_top--big"
          />
        ))}
      </div>
    </section>
    // </Fade>
  )
}

const Feature = ({ path, alt, title, description, right }) => {
  return (
    // <Fade bottom>
    <section className="row">
      {!right && (
        <div>
          <Image alt={alt} path={path} style="screen_mock" />
        </div>
      )}
      <div className="half_width left">
        <p className=" basic_text  bold big ">{title}</p>
        <p className="margin_top--big basic_text ">{description}</p>
      </div>
      {right && (
        <div>
          <Image alt={alt} path={path} style="screen_mock" />
        </div>
      )}
    </section>
    // </Fade>
  )
}

export default Feature
