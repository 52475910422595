import Axios from "axios"
import { ErrorMessage, Field, Form, Formik } from "formik"
import qs from "qs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Fade from "react-reveal/Fade"
import "semantic-ui-css/components/dimmer.min.css"
import "semantic-ui-css/components/modal.min.css"
import "semantic-ui-css/components/transition.min.css"
import { Modal } from "semantic-ui-react"
import * as Yup from "yup"
import "./i18n"
import Loader from "./loader"

const Contact = () => {
  let resetForm = undefined
  const [visible, setVisible] = useState(false)
  const { t, ready } = useTranslation("contact")
  const Schema = Yup.object().shape({
    email: Yup.string().email(t("emailError")).required(t("requiredError")),
    subject: Yup.string().required(t("requiredError")),
    message: Yup.string().required(t("requiredError")),
  })

  const handleSubmit = values => {
    const data = {
      to: "contact@zakazi.app",
      from: values.email,
      subject: values.subject,
      text: values.message,
      html: `<p>${values.message}</p>`,
    }
    Axios({
      method: "post",
      url: process.env.GATSBY_STRAPI_HOST.concat("/email/"),

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
    })
      .then(response => {
        if (response.status === 200) {
          resetForm && resetForm()
          openModal()
        }
      })
      .catch(e => console.log(e))
  }

  const closeModal = () => setVisible(false)
  const openModal = () => setVisible(true)
  return !ready ? (
    <Loader />
  ) : (
    <section className="section_background">
      <div className="col center margin_top--big fillWidth">
        <div className="col center">
          <p className=" basic_text dark bold big">{t("title")}</p>
        </div>
        <div className="col left fillWidth padding">
          <Formik
            initialValues={{
              email: "",
              subject: "",
              message: "",
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
            validateOnMount={false}
          >
            {props => {
              resetForm = props.resetForm
              return (
                <Form className="col left  fillWidth">
                  <Field
                    aria-label={t("email")}
                    id="email"
                    className="input"
                    type="email"
                    name="email"
                    placeholder={t("email")}
                  />
                  <Fade bottom collapse>
                    <ErrorMessage
                      component="span"
                      className="error_text"
                      name="email"
                    />
                  </Fade>
                  <Field
                    aria-label={t("subject")}
                    id="subject"
                    className="input"
                    type="text"
                    name="subject"
                    placeholder={t("subject")}
                  />
                  <ErrorMessage
                    component="span"
                    className="error_text"
                    name="subject"
                  />
                  <Field
                    aria-label={t("message")}
                    id="message"
                    as="textarea"
                    rows="5"
                    cols="30"
                    className=" multiline"
                    type="text"
                    name="message"
                    placeholder={t("message")}
                  />
                  <ErrorMessage
                    component="span"
                    className="error_text"
                    name="message"
                  />

                  <button type="submit" className="button margin_top">
                    {t("submit")}
                  </button>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
      <Modal size="mini" open={visible} onClose={closeModal}>
        <Modal.Header>{t("successfullySent")}</Modal.Header>

        <Modal.Actions>
          <button onClick={closeModal} className="button">
            {t("close")}
          </button>
        </Modal.Actions>
      </Modal>
    </section>
  )
}

export default Contact
